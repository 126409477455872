.ListHolder{
    width: 95%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    li{
        width: 17rem;
        // border:1px solid orange;
        margin: 0.5rem!important;
    }
}