.ConditionHolder{
    width: 95%;
    background: #E8E7E4;
    margin: 1rem 0;
    box-sizing: border-box;
    padding: 1rem ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .RightPart{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        margin-bottom: 1rem;
        p{
            direction: rtl;
            margin: 1rem 0;
        }
    }
}
.ReseVDivison{
    width: 100%;
    background: #E8E7E4;
    margin: 1rem 0;
    box-sizing: border-box;
    padding: 1rem ;
    .RightPart{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .priceHolder{
            font-size: 1.2rem;
            margin: 1rem 0;
            text-align: center;
            span{
                color: green;
            }
        }
    }
}
@media (min-width:999px) {
    .ConditionHolder{
        flex-direction: row!important;
        .RightPart{
            width: 60%;
            text-align: right;
        }
    }
}