@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.FormHolder{
    width: 100%;
    min-height: 85vh;
    .itemsHolder{
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        li{
            width: 47%;
            min-width: 280px;
            margin: 0.5rem;
            .inputName{
                font-size: 1.1rem;
                .redColor{
                    color: red;
                }
            }
            .btnHolder{
                width: 100%;
                margin: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .LocationHolder{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                select{
                    width: 49%;
                    height: 3rem;
                    border: 1px solid $GoldColor;
                    background: #fff;
                }
            }
            .FileLabel{
                width: 100%;
                height: 3rem;
                background: $GoldColor;
                @include centerValue;
                color: #fff;
                border-radius: 0.5rem;
                box-shadow: 0 2px 5px rgb(199, 199, 199);
                margin: 1rem 0;
                cursor: pointer;
            }
        }
        .TextAreaHolder{
            width: 95%;
            min-height: 4rem;
            input{
                height: 10rem;
            }
        }
    }
}
@media (min-width:999px) {
    .itemsHolder{
        li{
            margin-top: 3rem!important;
        }
    }
    
}