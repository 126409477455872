.ContainerHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 100%;
        height: 700px;
        object-fit: cover;
        cursor: pointer;
        margin: 1rem 0;
    }
    p{
        background: rgb(218, 218, 218);
        width: 100%;
        box-sizing: border-box;
        padding: 1rem;
        text-align: right;
        direction: rtl;
        margin: 1rem 0;
        line-height: 1.5rem;
        border-radius: 0.5rem;
    }
}