.confrimBtn{
    width: 145px;
    height: 50px;
    background-color: #F9C326;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-family:iran-sans;
    margin-inline: auto;
    font-size: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.459)!important;
}
.submitBtn{
    box-sizing: border-box;
    padding: 0.5rem 1.2rem;
    background-color: #1b8800;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-family:iran-sans;
    font-size: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.459)!important;
}
.primary{
    width: 145px;
    height: 50px;
    background-color: #ffffff;
    border-radius: 10px;
    border: 2px solid #F9C326;
    color: #F9C326;
    font-family:iran-sans;
    margin-inline: auto;
    font-size: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.459)!important;
}
.cancelBtn{
    width: 145px;
    height: 45px;
    background-color: #ffffff;
    border-radius: 10px;
    color: rgb(255, 115, 0);
    border: 1px solid rgb(255, 115, 0);
    font-family:iran-sans;
    margin-inline: auto;
    font-size: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.459)!important;
}
.Remove{
    width: 145px;
    height: 45px;
    background-color: #940606;
    border-radius: 10px;
    border: none;
    color: #ffffff;
    font-family:iran-sans;
    margin-inline: auto;
    font-size: 1rem;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.459)!important;
}
@media  only screen and (min-width:768px) {
    .confrimBtn,.cancelBtn{
        
        width: 190px;
        height: 50px;
    }
}