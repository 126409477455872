@import '../../../Sass/color.scss';
.SectionHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px rgb(231, 231, 231);
}
.SectionHolder h3{
    width: 100%;
    direction: rtl;
    text-align: right;
    color: $GoldColor;
    font-size: 1.3rem;
}
.DetailsList{
    width: 100%;
    margin: 1rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Detail{
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    border-bottom: 1px dashed $GoldColor;
}
.Detail p{
    margin: 0;
    padding: 0;
}