@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.PropertyHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    box-shadow: 0 2px 3px rgb(184, 184, 184);
    margin: 1rem 0;
    border-radius: 0.5rem;
    overflow: hidden;
    img{
        width:100%;
        height: 100%;
        object-fit: cover;
    }
    .ListHolder{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        li{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 0.5rem 1rem;
            p{
                margin: 0;
            }
            a{
                font-size: 1.1rem;
                color: $GoldColor;
            }
            .RemoveProperty{
                color: #fff;
                @include centerValue;
                box-sizing: border-box;
                padding: 0.3rem 2rem;
                background: #FE5252;
                border-radius: 0.5rem;
                cursor: pointer;
            }
            .EditBTN{
                color: #fff;
                @include centerValue;
                box-sizing: border-box;
                padding: 0.3rem 2rem;
                background: #ffc037;
                border-radius: 0.5rem;
                cursor: pointer;
            }
            .Address{
                svg{
                    color: $GoldColor;
                }
            }
        }
    }
}
@media (min-width:999px) {
    .PropertyHolder{
        flex-direction: row!important;
        height: 17rem!important;
        img{
            width: 20rem!important;
            
        }
        .ListHolder{
            width:calc(100% - 18rem)!important;
        }
    }
}