.SliderHolder{
    height: 15rem;
    width: 95%;
    .swiper {
        width: 100%;
        height: 100%;
        .swiperSlide {
        
        text-align: center;
        font-size: 18px;
        background: #fff;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
            a{
                width: 100%;
                height: 100%;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
      
    }

}
@media (min-width:750px) {
    .SliderHolder{
        height: 25rem!important;
    }
}
@media (min-width:1024px) {
    .SliderHolder{
        height: 35rem!important;
    }
}