.TitleHolder{
    font-size: 1rem;
    margin: 0.5rem 0;
}
.ImagePart{
    width: 40%;
    margin: 0.5rem 0;
}
.CollapseHolder{
    width: 95%;
    direction: rtl;
    text-align: right;
    position: relative;
    .IconHandler{
        position: absolute;
        left: 10px;
    }
    .TextHolder{
        direction: rtl;
        text-align: justify;
        line-height: 1.5rem;
    }
}