.LoadingPageHolder{
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.884);
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     .TextLoading{
        margin-top: 2rem;
        color:#fff;
        width: 100%;
        text-align: center;
    }

}
.displaynone{
    display: none;
}

