
@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.FormHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    .InputHolder{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        li{
            width: 260px;
            margin: 0.5rem;
            p{
                font-size: 0.8rem;
            }
            .TahatorHolder{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
        .ImagePerview{
            width: 320px!important;
            height: 200px !important;
            object-fit: cover;
            margin: 0.5rem 0;
        }
        .CheckBoxHolder{
            width: 260px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            flex-direction: row-reverse;
        }

    }
    .ImagePart{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .ImageLabel{
            background: goldenrod;
            color:#fff;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            padding: 0.5rem;
            box-shadow: 0 2px 3px rgb(199, 199, 199);
            border-radius: 0.5rem;
            margin: 0.5rem;
        }

    }
    .DescHolder{
        width: 100%;
        textarea{
            height: 10rem;
        }
    }
    .selectOption{
        width: 100%;
        select{
            
                outline: none;
                width: 100%;
                height: 2.5rem;
                padding: 6px;
                box-sizing: border-box;
                /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.521)!important; */
                border: 1px solid rgb(0, 3, 161);
                border-radius: 5px;
                /* border: none; */
                font-family:iran-sans-num;
              
        }
    }
}
@media (min-width:999px) {
    .InputHolder{
        justify-content: flex-start!important;
    }
    .ImagePerview{
        width: 60%!important;
        height: 430px!important;
    }
    .TahatorHolder{
        margin-top: 1.5rem;
    }
}