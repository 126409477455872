@import '../../../Sass/color';
.ContainerHolder{
    min-height: 650px;
    justify-content: center;
    box-sizing: border-box;
    padding: 1rem 0;
    .ContainerBG{
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
        
    }
    .SearchFiledHolder{
        margin: 0;
        padding: 0;
        width: 95%;
        box-sizing: border-box;
        padding: 1.3rem 1rem;
        background: rgba(255, 255, 255, 0.692);
        z-index: 3;
        h3{
            width: 100%;
            direction: rtl;
            text-align: center;
            font-size: 1rem;
            span{
                color: $GoldColor;
            }
        }
        h5{
            width: 100%;
            direction: rtl;
            text-align: center;
            font-size: 0.9rem;
            span{
                color: $GoldColor;
            }
        }
        .InputHolders{
            margin: 0;
            padding: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            .EachInput{
                width: 95%;
                height: 100%;
                // border: 1px solid orange;
                margin: 00.5rem 0;
            }
        }
        .SelectOptionHolder{
            background: #fff;
            border-radius: 0.6rem;
            border: none;
            padding-right: 0!important;
            direction: rtl;
            width: 100%;
            min-height:3.1rem;
            border-radius: 0.5rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.212);
            text-indent: 5px;

            
            div{
                border: none;
                // min-height: 100%!important;
                // width: 100%;
                min-height:2.7rem;
                text-align: right;
                direction: rtl;
                border-radius: 0.6rem;
                display: flex;
                align-items: center;
                z-index: 10;
            }


        }
        .TextInput{
            width: 100%;
            direction: rtl;
            text-align: right;
            border: none;
            min-height: 3.1rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.212);
            border-radius: 0.6rem;
        }
    }

}

@media (min-width:999px) {
    .SearchFiledHolder{
        width: 90%!important;
        h3{
            font-size: 1.3rem!important;
        }
        h5{
            font-size: 1.1rem!important;
            margin-top: 0.5rem;
        }
        .InputHolders{
            flex-direction: row!important;
            justify-content: center;
            direction: rtl;
            flex-wrap: wrap;
            .EachInput{
                width: 24%!important;
                margin: 0.5rem 0.2rem!important;
            }
        }
    }
    
}
