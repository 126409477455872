@import '../../../Sass/color.scss';
.formHolder{
    width: 100%;
    direction: rtl;
    box-sizing: border-box;
    padding: 0.5rem;
    background: #fff;
    h4{
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $GoldColor;
    }
    .inputName{
        margin-top: 1rem;
        font-size: 1.1rem;
        margin-right: 0.2rem;
        .redColor{
            color: red;
        }
    }
    .inputNameEn{
        direction: ltr;
        text-align: left;
        width: 98%;
        margin-top: 1rem;
        font-size: 1rem;
        margin-right: 0.2rem;
        .redColor{
            color: red;
        }
    }
    .btnHolder{
        width: 100%;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .LocationHolder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        select{
            width: 49%;
            height: 3rem;
            border: 1px solid $GoldColor;
            background: #fff;
            border-radius: 0.5rem;
        }
    }
}