.FormHolder{
    width: 100%;
    min-height: 85vh;
    .itemsHolder{
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        li{
            width: 45%;
            min-width: 280px;
            margin: 0.5rem;
            .inputName{
                font-size: 1.1rem;
                .redColor{
                    color: red;
                }
            }
            .btnHolder{
                width: 100%;
                margin: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .text{
                outline: none;
                width: 100%;
                height: 3rem;
                padding: 6px;
                box-sizing: border-box;
                /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.521)!important; */
                border: 1px solid #F1C40F;
                border-radius: 5px;
                /* border: none; */
                font-family: iran-sans;
            }
            .valid{
                border-width: 1px 10px 1px 1px;
                border-color: rgb(191, 22, 80);
                border-style: solid;
                transition: all .5s;
                width: 100%;
                height: 3rem;
                padding: 6px;
                box-sizing: border-box;
            }
        }
    }
}
@media (min-width:999px) {
    .itemsHolder{
        li{
            margin-top: 3rem!important;
        }
    }
    
}