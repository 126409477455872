.SectionHolder{
    margin: 0.5rem;
    width: 100%;
    box-shadow: 0 2px 3px gray;
    min-height: 10rem;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0.7rem;
    .ImageHolder{
        height: 9rem;
        width: 40%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ListHolder{
        width: 58%;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        li{
            box-sizing: border-box;
            padding: 0 0.5rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            h5{
                font-size: 0.9rem;
                color: goldenrod;
            }
        }
    }
}