@import '../../../Sass/color.scss';
.SectionHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .DescHolder{
        background: #fff;
        box-sizing: border-box;
        padding: 0.5rem;
        border-radius: 0.8rem;
        box-shadow: 0 0 3px rgb(231, 231, 231);
    }
}
.SectionHolder img{
    width: 100%;
    box-sizing: border-box;
    height: 20rem;
    object-fit: cover;
}
.SectionHolder h3{
    width: 100%;
    text-align: right;
    direction: rtl;
    margin: 1rem 0;
    color: $GoldColor;
    font-size: 1.3rem;
}
.SectionHolder div{
    width: 100%;
    text-align: justify;
    direction: rtl;
    margin-bottom: 1rem ;
    font-size: 1rem;
    line-height: 1.5rem;
}
@media (min-width:999px) {
    .SectionHolder img{
        height: 35rem!important;
    }
}