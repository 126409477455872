.ContainerHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .LeftPart{
        width: 100%;

    }
    .RightPart{
        width:100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

}
@media (min-width:999px) {
    .ContainerHolder{
        flex-direction: row!important;
        align-items: flex-start;
        .LeftPart{
            width: 22%;
        }
        .RightPart{
            width: 77%;
        }
    }

}