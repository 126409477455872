@import '../../../Sass/globalStyle';
@import '../../../Sass/color';
.HeaderTopPart{
    width: 95%;
    border-bottom: 2px solid #cccccc;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.6rem 0;
    padding-top: 1rem;
    .LoginRegisterBtn{
        width: 8rem;
        height: 2rem;
        flex-direction: row-reverse;
        justify-content: space-between!important;
        background: #fff;
        box-sizing: border-box;
        padding: 0.2rem 1rem;
        border-radius: 0.3rem;
        @include centerValue;
        cursor: pointer;
    }
    .ContactWays{
        display: flex;
        
        justify-content: space-between;
        align-items: center;
        .mail{
            display: none;
        }
        a{
            text-decoration: none;
            color:$NormalText;
            display: flex;
            align-items: center;
            svg{
                color: $GoldColor;
                margin-right: 0.3rem;
            }
        }
    }
}
@media (min-width:999px) {
    .HeaderTopPart{
        width: 90%;
    }
    .mail{
        display: block!important;
        margin-left: 1rem;
    }
}
@media (min-width:1360px) {
    .HeaderTopPart{
        width: 83%;
    }
}
@media (min-width:1400px) {
    .HeaderTopPart{
        max-width: 1320px;
    }
}