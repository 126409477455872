.SortItemModalHolder{
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    direction: rtl;
    .typeCheckbox{
        display: flex;
        flex-direction: column;
        label{
            margin: 0.5rem 0 0 0;
        }
    }
    .ExchangeHolder{
        margin: 1rem 0;
    }
}