.ListHolder{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    li{
        width: 280px;
        margin: 0.5rem;
    }
}