@import '../../../Sass/globalStyle';
@import '../../../Sass/color';
.NavbarHolder{
    border: darkblue!important;
    width: 100%!important;
    margin-bottom: 1rem;
    position: relative;
    /* background-color: royalblue!important; */
    .NavbarContainer{
        direction: rtl;
        .navText{
            color: #002349!important;
            margin-left: 1rem;
            
        }
        .navText p{
            margin: 0;
        }

        .navLogo{
            display: flex;
            font-size: 0.9rem;
            .logoNavbar{
                width: 3rem;
            }
        }

        .navText a,.navLogo a,.navCourse a{
            color: #002349!important;
            display: flex;
            align-items: center;
        }
        .navText a svg{
            margin-right: 0.3rem;
        }
        
        
        .navCourse{
            color: #002349!important;
            margin-left: 1rem;
            .navCourse p{
                margin: 0;
            }
        }

        
        .CourseDropDown{
            text-align: right;
            line-height: 1.5rem;
        }
        
        .CreateAdvertising{
            @include centerValue;
            color: #fff;
            background: $GoldColor;
            padding: 0.3rem 2rem;
            border-radius: 0.3rem;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.521);
            margin-left: 0.5rem;
            display: none;
            cursor: pointer;
        }
    }
    
   
}

@media(min-width:999px){
    .navText a svg{
        margin: 0;
    }
    .CourseDropDown{
        width: 480px;
        right: 0!important;
        margin: 1rem 0;
        line-height: 1.5rem;
    }
    .navCourse div{
        position: absolute;
        width: 250px;
        right: 0;
        direction: rtl;
        text-align: right;
    }
    .navLogo{
        margin-left: 2rem;
    }
    .CreateAdvertising{
        display: block!important;
        @include centerValue;
        color: #fff;
        background: $GoldColor;
        padding: 0.3rem 2rem;
        border-radius: 0.3rem;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.521);
        margin-left: 0.5rem;
    }
}