@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.ListHolder{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li{
        width: 25%;
        min-width: 15rem;
        box-sizing: border-box;
        padding: 0.5rem;
        p{
            margin: 0;
            width: 100%;
            @include centerValue;
            background: $GoldColor;
            color: #fff;
            height: 4rem;
            border-radius: 0.5rem;
            box-shadow: 0 2px 5px rgb(151, 151, 151);
            cursor: pointer;
        }
    }
    .active{
        p{
            background: $GreenColor;
            transform: scale(1.05);
            transition: all 0.5s ease-in-out;
        }
    }
}