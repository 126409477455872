
.ListHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    li{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid rgb(201, 201, 255);
        margin: 1rem 0;
        .MessageDetailAdmin{
            margin: 0;
            box-sizing: border-box;
            padding: 1rem ;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: lightgreen;
            p{
                margin: 0;
            }
        }
        .MessageDetailUser{
            width: 100%;
            box-sizing: border-box;
            padding: 1rem ;
            display: flex;
            align-items: center;
            margin: 0;
            justify-content: space-between;
            background: rgb(212, 212, 212);
            p{
                margin: 0;
                direction: auto;
            }
        }
        .MessageText{
            direction: rtl;
            text-align: right;
            box-sizing: border-box;
            padding: 1rem;
            line-height: 1.5rem;
            
        }   
    }
}