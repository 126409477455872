@import '../../../Sass/color';

.HeaderHolder{
    .Logo{
        height: 3rem;
       
    }
    p{
        font-size: 0.9rem;
        text-align: center;
        box-sizing: border-box;
        padding: 0.5rem;
        margin: 0;
        color: $BlueColor;
        span{
            color: $GoldColor;
        }
    }
}
@media (min-width:999px){
    .HeaderHolder{
        .Logo{
            height: 5rem!important;
        }
        p{
            font-size: 1.5rem!important;
        }

    }

}