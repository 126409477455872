@import '../../../Sass/color.scss';
.ComponentHolder{
    background: #6C6C6C;
    height: 100%!important;
    border-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh!important;
    .CloseSideBar{
        width: 100%;
        text-align: left;
        box-sizing: border-box;
        padding: 1rem;
        color: #fff;
        font-size: 1.3rem;
    }
    .UserInfo{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4{
            width: 100%;
            text-align: center;
            direction: rtl;
            color: #fff;
            box-sizing: border-box;
            padding: 0.5rem ;
            font-size: 1.1rem;
            margin-top: 1rem;
        }
        p{
            width: 95%;
            text-align: center;
            direction: rtl;
            color: #fff;
            box-sizing: border-box;
            padding: 0.5rem 0 1rem 0;
            margin: 0;
            span{
                color: $GoldColor;
            }
            border-bottom: 1px solid  $GoldColor;
        }
    }
    .ProfileItems{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        li{
            width: 100%;
            display: flex;
            align-items: center;
            direction: rtl;
            color: #fff;
            justify-content: space-between;
            height: 3rem;
            margin: 0.5rem 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: all 0.5s ease-in-out ;
            p{
                margin: 0;
            }

            
        }
        .ActiveItem{
            background: #fff!important;
            color: #000!important;
            transition: all 0.5s ease-in-out ;
        }
        .AccordionRegular{
            width: 100%;
            display: flex;
            align-items: center;
            direction: rtl;
            color: #fff;
            height: auto!important;
            justify-content: space-between;
            margin: 0.5rem 0;
            padding: 0.5rem 1rem;
            cursor: pointer;
            transition: all 0.5s ease-in-out ;
            p{
                margin: 0;
            }
            .titlePart{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: rgb(255, 255, 255)!important;
            }
        }
       .AccordionAvtive{
            background: #fff!important;
            color: #000!important;
            transition: all 0.5s ease-in-out ;
            display: flex;
            flex-direction: column;
            height: auto!important;
            .titlePart{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #000!important;
                box-sizing: border-box;
                padding-bottom: 0.5rem;
                border-bottom: 1px dashed gray;
            }
            .AcardionBody{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 0.5rem;
                div{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 2rem;
                }
            }

       }
       .SignOut{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: rgb(255, 136, 114);
       }
    }
}

@media (min-width:999px) {
    .ComponentHolder{
        .CloseSideBar{
            display: none!important;
        }
    }

}