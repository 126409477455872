.FilterComponent{
    width: 100%;
    // border:1px solid purple;
    height: 30rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin-top: 3rem;
    .sectionFilter{
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        width: 90%;
        background: #fff;
        box-sizing: border-box;
        padding: 1rem;
        box-shadow: 0 2px 5px rgb(192, 192, 192);
        border-radius: 0.5rem;
        direction: rtl;
        h3{
            margin: 0;
            font-size: 1.2rem;
        }
        .typeCheckbox{
            display: flex;
            flex-direction: column;
            label{
                margin: 0.5rem 0 0 0;
            }
        }
        .SelectOptionHolder{
            border: none;
            padding-right: 0!important;
            direction: rtl;
            width: 100%;
            min-height:2.2rem;
            border-radius: 0.2rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.212);
            text-indent: 5px;
            margin-bottom: 1rem;
            
            div{
                border: none;
                min-height:2.2rem;
                // height: 100%!important;
                // width: 100%;
                text-align: right;
                direction: rtl;
                border-radius: 0.2rem;
                display: flex;
                align-items: center;
                z-index: 10;
            }
        }
        .TextInput{
            width: 100%;
            direction: rtl;
            text-align: right;
            border: none;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.212);
            min-height:2.2rem;
            border-radius: 0.2rem;
            margin-bottom: 1rem;
        }
    }
}
@media (max-width:999px) {
    .FilterComponent{
        margin-bottom: 3rem;
    }
}