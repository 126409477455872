@import '../../../Sass/color.scss';
.ListHolder{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .propertyHolder{
        width: 15rem;
        min-height: 21rem;
        // border: 1px solid orange;
        margin:0.5rem;
    }
    .Pagination{
        width: 100%;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .ListNumber{
            margin: 0 0.35rem;
            a{
                background: rgb(255, 255, 255);
                box-shadow: 0 2px 5px rgb(216, 216, 216);
                border-radius: 50%;
                height: 2rem;
                width: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding-top: 0.5rem;
                color: #2c2c2c;
            }
            span{
                box-shadow: 0 2px 5px rgb(216, 216, 216);
                border-radius: 50%;
                height: 2rem;
                width: 2rem;
                background: $GoldColor;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                padding-top: 0.5rem;
            }
            
        }
        li:last-child,li:first-child{
            margin: 0 0.35rem;
            a{color: #2c2c2c;}
        }
    }
}