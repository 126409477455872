.RegisterSection{
    width: 100%;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem 0;
    img{
        width: 35%;
        display: none;
    }
    .formHolder{
        width: 95%;
        // border: 1px solid orange;
        border-radius: 0.5rem;
        overflow: hidden;
        box-shadow: 0 3px 6px rgb(179, 179, 179);
    }
}
@media (min-width:999px) {
    .RegisterSection{
        flex-direction: row-reverse;
        justify-content: space-around;
        img{display: block;}
    }
    .formHolder{
        width: 45%!important;
    }
}