@import '../../../Sass/color.scss';
.ContainerHolder{
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
    box-sizing: border-box;
    padding: 2rem;
    .FilterIcon{
        display: none;
    }
    .FilterPart{
        width: 25%;
        min-height: 35rem;
    }
    .ListPart{
        width: 75%;
        .SortItemBTN{
            display: none;
        }
        .SortItemHolder{
            width: 100%;
            display: flex;
            align-items: center;
            direction: rtl;
            height: 2.2rem;
            p{
                margin: 0!important;
                padding: 0;
            }
            label{
                margin:0 0.2rem;
            }
            .TahatorHolder{
                margin: 0 0.5rem;
            }

        }
    }
    
}
@media (max-width:999px) {
    .ContainerHolder{
        .FilterIcon{
            display: block;
            position: fixed;
            background: $GoldColor;
            bottom: 90px;
            right: 15px;
            height: 3rem;
            width: 3rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 1.3rem;
            box-shadow: 0 2px 5px rgb(196, 196, 196);
            z-index: 100;
        }
        .FilterPart{
            display: none;
        }
        .ListPart{
            .SortItemBTN{
                display: block;
                width: 100%;
                direction: rtl;
            }
            width: 100%;
            .SortItemHolder{
                display: none;
            }
        }
        .ModalHandler{
            height: 100rem;
        }
    }
    
}
