.TitlePage{
    width: 95%;
    margin: 1rem 0;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 600;
}
.contentPage{

    width: 98%;
    box-sizing: border-box;
    padding: 1rem;
    direction: rtl;
}