.pageHolder{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.modal{
    width:85%;
    max-height: 900px;
    background: #ffffff;
    padding: 30px;
    z-index: 300;
    border-radius: 5px;
    position: fixed;
    box-sizing: border-box;
    transition: all 1s ease-out;
    direction: rtl;
    overflow: hidden;
    overflow-y: scroll;
}
@media (min-width:850px){
    .modal{
        width: 730px;
    }
}
