.ComponentHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .BodyHolder{
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: center;
        direction: rtl;
        justify-content: space-between;
        box-sizing: border-box;
        img{
            width:15rem;
        }
        p{
            width: 100%!important;
            width: calc(100% - 22rem);
            text-align: justify;
            direction: rtl;
            font-size: 1.2rem;
            margin: 1rem;
        }
    }
}
@media (min-width:999px) {
    .BodyHolder{
        flex-direction: row!important;
        align-items: flex-start!important;
        img{
            width: 20rem!important;
        }
        p{
            width: calc(100% - 22rem)!important;
        }
    }
}