.SectionHolder{
    width: 98%;
    box-shadow: 0 3px 3px rgb(216, 216, 216);
    box-sizing: border-box;
    padding: 1rem;
    margin: 0.5rem auto;
    display: flex;
    flex-wrap:wrap ;
    justify-content: space-around;
    h3{
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        color: goldenrod;
    }
    .info{
        width: 12rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0.5rem 0;
        h6{
            color: goldenrod;
        }
    }
}