@import '../../Sass/color.scss';
.ComponentHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-sizing: border-box;
    padding: 1rem;
    box-shadow: 0 2px 5px rgb(202, 202, 202);
    img{
        width: 100%;
        height:17rem;
        object-fit: cover;
    }
    .InfoHolder{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: rtl;
        margin: 0.3rem 0;
        a{
            color: $BlueColor;
        }
        .Date{
            color: $GoldColor;
        }
        span{
            color: $GreenColor;
        }
    }
}