@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.InfoListHolder{
    width: 100%;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-shadow: 0 2px 4px rgb(214, 214, 214);
    li{
        width: 100%;
        display: flex;
        flex-direction: column;
        direction: rtl;
        box-sizing: border-box;
        padding: 0.5rem ;
        border-bottom: 1px dashed $GoldColor;
        h3{
            font-size: 1.1rem;
            width: 100%;
            text-align: center;
            color: $GoldColor;
        }
        span{
            font-size: 0.9rem;
            color: gray;
            margin-bottom: 0.5rem;
        }
        p{
            font-size: 1rem;
            margin: 0;
            font-family:Roboto!important;
        }
        h5{
            width: 12rem;
            height: 3rem;
            background: $RedColor;
            color: #fff;
            font-size: 0.9rem;
            @include centerValue;
            margin-inline: auto;
            border-radius: 0.5rem;
            box-shadow: 0 2px 3px gray;
            margin-top: 0.5rem;
            cursor: pointer;
        }
    }
    li:last-child{
        border-bottom: none;
    }
}