@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.ComponentHolder{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    div{
        width: 48%;
        @include centerValue;
        background: #fff;
        height: 100%;
        border-top: 1px solid rgb(243, 243, 243);
        border-bottom: 1px solid rgb(243, 243, 243);
        transition: all 0.5s ease-in-out;
        cursor: pointer;
    }
    .Active{
        width: 52%;
        border-bottom: 3px solid $GoldColor;
        color: $GoldColor;
        font-size: 1.2rem;
        transition: all 0.5s ease-in-out;
    }
}