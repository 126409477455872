@import '../../Sass/color.scss';
.countdown
{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    direction: rtl;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    font-family:iran-sans-num;
    padding: 0;
    color:$GoldColor ;
    li{
        display: flex;
        justify-content: center;
    }
}
