@import '../../../Sass/color.scss';
.formHolder{
    width: 100%;
    direction: rtl;
    box-sizing: border-box;
    padding: 0.5rem;
    background: #fff;
    h4{
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $GoldColor;
    }
    .inputName{
        margin-top: 1rem;
        font-size: 1rem;
        margin-right: 0.2rem;
        .redColor{
            color: red;
        }
    }
    .btnHolder{
        width: 100%;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}