@font-face {
  font-family:Roboto ;
  src: url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
}
.pageHolder{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
  background: #F9F9F9;
}
.flexStyle{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 0 auto;
}
body{
  font-family: iran-sans-num;
  background: #fff;
}
.RTL{
  direction: rtl;
}
.text{
  outline: none;
  width: 100%;
  height: 2.5rem;
  padding: 6px;
  box-sizing: border-box;
  /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.521)!important; */
  border: 1px solid rgb(0, 3, 161);
  border-radius: 5px;
  /* border: none; */
  font-family:iran-sans-num;
  direction: rtl;
  text-align: right;
}
textarea.text{
  min-height: 6rem;
  resize: none;
}
.valid{
outline: none;
  width: 100%;
  height: 2.5rem;
  border-radius: 5px;
  /* border: none; */
  font-family: iran-sans-num;
  direction: rtl;
  text-align: right;
  border-width: 1px 10px 1px 1px;
  border-color: rgb(191, 22, 80);
  border-style: solid;
  transition: all .5s;
  animation: shake 300ms;
}
textarea.valid{
  min-height: 6rem;
  resize: none;
}
@keyframes shake{
  25%{
    transform: translateX(4px);
  }
  50%{
    transform: translateX(-4px);
  }
  75%{
    transform: translateX(4px);
  }
}
.textEn{
  outline: none;
  width: 100%;
  height: 2.5rem;
  padding: 6px;
  box-sizing: border-box;
  /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.521)!important; */
  border: 1px solid rgb(0, 3, 161);
  border-radius: 5px;
  /* border: none; */
  font-family:Roboto!important;
  text-align: left;
}