.SliderHolder{
    width: 98%;
    .swiper {
        width: 100%;
        height: 100%;
        .swiperSlide{
            text-align: center;
            font-size: 18px;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            box-sizing: border-box;
            padding: 0.5rem;
          }
      }
    
}

