@import '../../../Sass/color.scss';
.ListHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:1rem 0;
    padding: 0;
    li{
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-bottom:1px dashed $GoldColor;

        text-align: center;
        box-sizing: border-box;
        padding:  1rem 0;
        .PriceInfo{
            h3{
                font-size: 1rem;
                color: $GreenColor;
            }
            p{
                color: $GoldColor;
            }
        }
        .transactionType{
            .AddMoney{
                background: $GreenColor;
                color: #fff;
                padding: 0.5rem 2rem;
            }
            .removeMoney{
                background: $RedColor;
                color: #fff;
                padding: 0.5rem 2rem;
            }
        }
    }
}
@media (min-width:999px) {
    .ListHolder{
        li{
            flex-direction: row!important;
            justify-content: space-between;
        }
    }
}