.FormHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    .RadipListHolder{
        width: 100%;
        margin-bottom: 1rem ;
        display: flex;
        flex-direction: column;
        label{
            margin: 0.3rem 0;
        }
    }
    .AlertHolder{
        margin-bottom: 0.5rem;
    }
    .CheckBoxHolder{
        margin: 1rem 0;
    }
}