@import '../../Sass/color.scss';
.ComponentHolder{
    width: 95%;
    height: 7rem;
    box-sizing: border-box;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    direction: rtl;
    background: #fff;
    border-radius: 1rem;
    box-shadow: 0 2px 5px gray;
    margin: 1rem 0;
    p{
        margin: 0;
        padding: 0;
        direction: rtl;
        font-size: 1.2em;
        margin: 0.5rem 0;
        svg{
            font-size: 1.5rem;
            color: $BlueColor;
        }
    }
}
@media (min-width:720px) {
    .ComponentHolder{
        flex-direction: row;
    }
}