.ContainerHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    img{
        width: 40%;
    }
    h3{
        direction: rtl;
        text-align: center;
        color: green;
    }
    .DetailTabel{
        margin: 0;
        padding: 0;
        width: 95%;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        li{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            direction: rtl;
            padding: 0.5rem;
            box-sizing: border-box;
            border-bottom: 1px dashed gray;
            p{
                margin: 0
            }
            h5{
                font-size: 1rem;
                color: green;
            }
        }
    }
}