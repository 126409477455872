.sectionHolder{
    width: 98%;
    border: 1px solid rgb(214, 214, 214);
    margin: 1rem 0.5rem;
    box-sizing: border-box;
    padding:0.5rem;
    box-shadow: 0 2px 3px rgb(207, 207, 207);
    .DescHolder{
        width: 100%;
        span{
            color: red;
        }
    }
    .RequestTitle{
        width: 100%;
        text-align: center;
        box-sizing: border-box;
        padding: 1rem;
        font-size: 1.2rem;
        color: goldenrod;
    }
    .RequestHolder{
        width: 100%;
        .PropertyHolder{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            border: 1px solid gold;
            border-radius: 0.5rem;
            margin: 0.5rem 0;
            box-sizing: border-box;
            padding: 0.7rem;
            img{
                width: 100%;
                height:12rem;
            }
            h3{
                color: goldenrod;
                font-size: 1.1rem;
            }
            .ListHolder{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 0;
                padding: 0;
                width: 100%;
                li{
                    margin: 0.2rem 0;
                    background: rgb(238, 238, 238);
                    width: 100%;
                    text-align: center;
                    box-sizing: border-box;
                    padding: 0.4rem;
                }
            }
        }
    }
    .BtnHolder{
        width:100%!important;
        margin: 0.25rem 0 !important;
        border:none;
    }
    
}
@media (min-width:750px) {
    .RequestHolder{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .PropertyHolder{
            width: 20rem!important;
        }
    }
    .BtnHolder{
        width: 48%!important;
        margin:0 0.5rem !important;
    }
}