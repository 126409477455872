@import '../../Sass/color';
.PropertySection{
    width:100%;
    background: #fff;
    box-shadow: 0 2px 6px rgba(51, 51, 51, 0.178)!important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 00.5rem;
    box-sizing: border-box;
    padding: 1rem;
    position: relative;
    overflow: hidden;
    margin-top: 1rem;

    .PropertyInfo{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0;
        .ImageHolder{
            width: 100%;
            height: 11.5rem;
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
         
        .PropertyName{
            width: 100%;
            text-align: right;
            line-height: 1.5rem;
            margin: 0.5rem 0;
            a{
                color: $GoldColor;
            }
        }
        .info{
            display: flex;
            align-items: center;
            direction: rtl;
            justify-content: space-between;
            width: 100%;
            margin: 0.3rem 0;
            h6{
                color: #3f3f3f;
                padding: 0;
                margin: 0;
                font-size: 0.9rem!important;
            }
             p
            {
                padding: 0;
                margin: 0;
                font-size: 0.9rem!important;
            }
        }
        .situation{
            width: 100%;
            display: flex;
            direction: rtl;
            align-items: center;
            font-size: 0.9rem;
            margin-top: 0.5rem;
            svg{
                color: $GoldColor;
            }
            p{
                margin: 0 0.5rem 0 0;
            }
            a{
                margin-right: 0.5rem;
                text-decoration: none;
                color: black;
            }
        }
        
    }
    
}
