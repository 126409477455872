@import '../../../Sass/color.scss';
.PropertySection{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5rem 0.5rem 2rem  0.5rem;
    background: #fff;
    box-shadow: 0 2px 5px rgb(209, 209, 209);
    border-radius: 5px;
    .ImageHolder{
        width: 100%;
        height: 9rem;
        position: relative;
        img{
            width: 100%;
            object-fit: cover;
            height:100%;
            position: absolute;
            z-index: 1;
        }
        p{
            z-index: 2;
            position: absolute;
            top: 5px;
            left: 5px;
            font-weight: 600;
            color: rgb(255, 255, 255);
            text-shadow: 0px 0px 3px rgb(0, 0, 0);
            
        }
    }
    h3{
        width: 100%;
        text-align: right;
        direction: rtl;
        font-size: 1rem;
        color: $GoldColor;
        margin-top: 0.6rem;
    }
    .locationProperty,.projectProperty{
        width: 100%;
        display: flex;
        align-items: center;
        direction: rtl;
        margin: 0;
        padding: 0;
        margin-bottom: 0.5rem;
        color: #000;
        svg{
            color: $GoldColor;
            margin-left: 0.2rem;
        }
        
    }
    .propertyDetail{
        
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        direction: rtl;
        margin-bottom: 0.5rem;
        p{
            margin: 0;
        }
        .PriceProperty{
            color: rgb(0, 180, 0);
        }
    }
    .optionHolder{
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        background: $GoldColor;
        border-radius: 5px;
        p{
            margin: 0;
            padding: 0;
            direction: rtl;
            text-align: right;
        }
        .OptionsHolder{
            box-sizing: border-box;
            padding: 0.2rem 0.3rem;
            background: $GoldColor;
            font-size: 0.7rem;
            width: 45%;
            border-bottom-right-radius: 5px;
            display: flex;
            justify-content: center;
        }
        a{
            color: #fff;
            background: #2A2828;
            padding: 0.2rem 0.5rem;
            font-size: 0.7rem!important;
            width: 55%;
            border-bottom-left-radius: 5px;
            display: flex;
            justify-content: center;
            clip-path: polygon(0 0, 86% 0, 100% 99%, 0 100%);
        }
    }
}