.CheckBoxHolder{
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding:0 1rem;
    span:nth-child(2){
        width: 100%;
        span{
            width: auto;
        }
    }
    span:nth-child(1){
        width:1rem;
    }
}