.ComponentHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        width: 100%;
        height: 900px;
        // object-fit: cover;
        cursor: pointer;
        margin: 1rem 0;
    }
    .DescHolder{
        background: rgb(218, 218, 218);
        width: 100%;
        box-sizing: border-box;
        padding: 1rem;
        text-align: right;
        direction: rtl;
        margin: 1rem 0;
        line-height: 1.5rem;
        border-radius: 0.5rem;
    }
}
@media (min-width:999px) {
    .ComponentHolder{
        flex-direction: row;
        justify-content: space-between;
        div{
            width: 48%;
            display: flex;
            flex-direction: column;
        }
    }
    
}