@import '../../../Sass/globalStyle.scss';
.ListHolder{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li{
        width: 11rem;
        position: relative;
        height: 15rem;
        margin: 0.5rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        span{
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.356);
        }
        a{
            z-index: 2;
            width: 100%;
            background: #000;
            height: 2rem;
            position: absolute;
            bottom: 0;
            left: 0;
            color: #fff;
            @include centerValue;
            margin: 0;
            padding: 0;
        }
    }
}