@import '../../../Sass/color.scss';
.SectionHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ProjecImage{
        width: 98%;
    }
    .DescHolder{
        width: 100%;
        text-align: justify;
        direction: rtl;
        margin-bottom: 1rem ;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .DescHolder{
        width: 100%;
        background: #fff;
        box-sizing: border-box;
        padding: 0.5rem;
        border-radius: 0.3rem;
        box-shadow: 0 2px 3px rgb(219, 219, 219);
    }
}
.SectionHolder .swiper{
    width: 100%;
    box-sizing: border-box;
    height: 30rem;
    
}
.swiperSlide img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.SectionHolder h3{
    width: 100%;
    text-align: right;
    direction: rtl;
    margin: 1rem 0;
    color: $GoldColor;
    font-size: 1.3rem;
}

@media (min-width:999px) {
    .SectionHolder img{
        height: 30rem!important;
        // width: 70%!important;
    }
}