.ContainerHolder{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    box-sizing: border-box;
    padding: 0 0.8rem;
    div{
        width: 100%;
        margin: 0.5rem 0;
    }
    .AccordionHeader{
        position: relative;
        button::after{
            position: absolute;
            left: 5px;
        }
    }
}
@media (min-width:999px) {
    .ContainerHolder{
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        .SideInfo{
            width: 22%;
            margin: 0.5rem;
        }
        .Messages{
            width: 70%;
            margin: 0.5rem;
        }
    }
}
