@import '../../../Sass/color.scss';
@import '../../../Sass/globalStyle.scss';
.SectionHolder{
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 2px 5px rgb(182, 182, 182);
    box-sizing: border-box;
    padding: 2rem 1rem;
    img{
        width: 95%;
    }
    .InfoHolder{
        margin: 1rem 0 0 0;
        padding: 0;
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        li{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            direction: rtl;
            min-height: 3rem;
            p{
                margin: 0;
                padding: 0;
            }
        }
        .PropertyCode{

            span{
                background: $GoldColor;
                color: #fff;
                box-sizing: border-box;
                padding: 0.2rem 1rem;
            }
        }
        .PriceHolder{
            span{
                color: $GreenColor;
            }
        }
        .ModalGallery{
            background: $GoldColor;
            @include centerValue;
            color: #fff;
            height: 2.5rem;
            margin: 0.25rem 0;
            cursor: pointer;
        }
        .ModalFavorit{
            background: $NormalText;
            @include centerValue;
            color: #fff;
            height: 2.5rem;
            margin: 0.25rem 0;
            cursor: pointer;
        }
        .TahatorBTN{
            background: orange;
            @include centerValue;
            color: #fff;
            height: 2.5rem;
            margin: 0.25rem 0;
            cursor: pointer;
        }
        .ModalReserv{
            background: $GreenColor;
            @include centerValue;
            color: #fff;
            height: 2.5rem;
            margin: 0.25rem 0;
            cursor: pointer;
        }
        .ModalBuy{
            border:1px solid $GreenColor;
            background: #fff;
            @include centerValue;
            color: $GreenColor;
            height: 2.5rem;
            margin: 0.25rem 0;
            cursor: pointer;
        }
    }
}