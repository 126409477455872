.SectionHolder{
    width: 100%;
}
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .SwiperSlide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .SwiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } .SwiperSlide video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  body {
    background: #000;
    color: #000;
  }
  
  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .SwiperSlide {
    background-size: cover;
    background-position: center;
    height: 30rem;
  }
  
  .mySwiper2 {
    height: 80%;
    width: 100%;
  }
  
  .mySwiper {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }
  
  .mySwiper .SwiperSlide {
    width: 25%;
    height: 100%;
    height: 5rem;
  }
  
  .mySwiper .SwiperSlide-thumb-active {
    opacity: 1;
  }
  
  .SwiperSlide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .SwiperSlide video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }