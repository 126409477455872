@import '../../Sass/color';
.PartHolder{
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    img{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
    }
    span{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.671);
    }
    p{
        position: absolute;
        margin: 0;
        padding: 0;
        top: 30px;
        left: 30px;
        z-index: 3;
        color:$GoldColor;
        font-size: 1.3rem;
    }
    .Divider{
        color: $GoldColor;
        width: 95%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        text-align: right;
        position: absolute;
        bottom: 30px;
        h6{
            z-index:5;
            width: 6rem;
            color: $GoldColor;
            font-size: 1.3rem;
            display: flex;
            justify-content: center;
            margin: 0;
        }
        hr{
            width: 95%;
            border-color: rgb(212, 212, 212);
            border-width: 3px;
        }
    }
}