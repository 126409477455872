.magazineHolder{
    display: none;
}
@media (min-width:999px) {
    .magazineHolder{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}