.InputHolder
{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
::placeholder
{
    color: rgb(158, 158, 158);
    direction: rtl!important;
}
.InputStyle
{
    outline: none;
    width: 100%;
    height: 3rem;
    padding: 6px;
    box-sizing: border-box;
    /* box-shadow: 0 .125rem .25rem rgba(0, 0, 0, 0.521)!important; */
    border: 1px solid #F1C40F;
    border-radius: 5px;
    /* border: none; */
    font-family: iran-sans;
}
input[type=number] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.confirmNumber
{
    text-align: center;
    letter-spacing:10px;
    border-radius: 50px;
    background: #ffff;
    font-family: iran-sans-num;
}
.textareaStyle{
    width: 100%;
    border-radius: 5px;
    border: none;
    font-family: iran-sans;
    box-sizing: border-box;
    max-height: 180px;
    min-height: 140px;
    background: #ffffff;
    padding: 0.5rem;
    border: 1px solid #F1C40F;
    /* border: 1px solid red; */
    direction: rtl;
}
.textareaStyle::placeholder
{
    font-size: 0.8rem;
}
.Note{
    width: 100%;
    box-shadow: 0 2px 5px gray;
    border-radius: 5px;
    border: none;
    font-family: iran-sans;
    box-sizing: border-box;
    max-height: 100px;
    min-height: 60px;
    background: rgb(238, 238, 238);
    font-size: 0.8rem;
}
.Note::placeholder
{
    font-size: 0.8rem;
}
.file{
    display: none;
}
.UnderLine
{
    border: none;
    border-bottom: 1px solid #CBCBCB;
    box-shadow: none;
    border-radius: 0;
    height: 3.5rem;
    text-align: right;
    direction: rtl;
    outline: none;
    width: 100%;
    font-family: iran-sans;
}
.Counter
{
    outline: none;
    width: 80%;
    height: 2rem;
    padding: 6px;
    box-sizing: border-box;
    box-shadow:  0 0 6px #37373733;
    border-radius: 5px;
    border: none;
    font-family: iran-sans-num;
    text-align: center;
    font-size: 0.7rem;
}
.isInvalid{
    border-width: 1px 10px 1px 1px;
    border-color: rgb(191, 22, 80);
    border-style: solid;
    transition: all .5s;
    animation: shake 300ms;
}
@keyframes shake{
    25%{
      transform: translateX(4px);
    }
    50%{
      transform: translateX(-4px);
    }
    75%{
      transform: translateX(4px);
    }
  }
@media  only screen and (min-width:320px) {
    .textareaStyle{
       height: 9rem;
    }
}
@media  only screen and (max-width:280px) {
    .textareaStyle{
       height: 8rem;
    }
}
@media  only screen and (min-width:999px) {
    .InputHolder{
        width: 100%;
    }
}