.TabelHolder{
    width: 95%;
    background: #fff;
    overflow-x: scroll;
    
}
.TabelHolder table{
    direction: ltr!important;
    min-width: 900px;
    overflow-x: scroll;
    
}
.TabelHolder table th div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}
.TabelHolder table th div span{
    text-align: left;
}
.TabelHolder table td{
    direction: ltr!important;
    text-align: left;
    font-family:Roboto!important;
}
.TabelHolder table th{
    background: #fff;
}
.TabelHolder table tr:nth-child(2n+1){
    background: rgb(235, 235, 235);
}
.TabelHolder table td:nth-child(2){
    color: green;
}
.TabelHolder table td:nth-child(3){
    color: rgb(187, 0, 0);
}