@import '../../Sass/color';
@import '../../Sass/globalStyle';
.FooterContainer{
    margin-top: 2rem;
    background: #f2f2f2f2;
    box-sizing: border-box;
    padding: 0.6rem 0;
    .TopFooter{
        width: 95%;
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: center;
        border-bottom: 2px solid #d6d6d6;
        box-sizing: border-box;
        margin-bottom: 1rem;
        padding: 0.6rem 0;
        padding-top: 1rem;
        .LogoPart{
            display: flex;
            flex-direction: column;
            align-items: center;
            p{
                margin: 0;
                margin-top: 0.6rem;
                font-size: 1.3rem;
            }
            img{
                width: 4rem;
            }
        }
        .SocialMedia{
            display: flex;
            margin-top: 0.5rem;
            align-items: center;
            a{
                background: $GoldColor;
                color: #fff;
                height: 2.6rem;
                width: 2.6rem;
                font-size: 1.8rem;
                @include centerValue;
                border-radius:50%;
                box-shadow: 0 2px 3px rgb(117, 117, 117);
                margin: 0 0.6rem;
            }
        }
    }
}
@media (min-width:999px) {
    .TopFooter{
        flex-direction: row-reverse!important;
        justify-content: space-between;
        width: 90%;
        .LogoPart{
            flex-direction: row-reverse!important;
            p{
                margin-right: 0.6rem!important;
            }
        }
    }
}
@media (min-width:1360px) {
    .TopFooter,.divider{
        width: 83%!important;
    }
}
@media (min-width:1400px) {
    .TopFooter,.divider{
        max-width: 1320px!important;
    }
}