@import '../../../Sass/color.scss';
.MagazineTopPage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 1rem 0;
    h3{
        color: $GoldColor;
    }
    .FilterHolder{
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-around;
        .SelectOptionHolder{
            background: #fff;
            border-radius: 0.6rem;
            border: none;
            padding-right: 0!important;
            direction: rtl;
            width: 45%;
            height:2.3rem;
            border-radius: 0.5rem;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.212);
            text-indent: 5px;

        }
    }
}
.divider{
    border-color: rgb(182, 182, 182);
    min-width: 95%!important;
    width: 95%!important;
    margin: 0!important;
}
@media (min-width:999px) {
    .MagazineTopPage{
        width: 95%;
        flex-direction: row-reverse!important;
        justify-content: space-between;
        h3{
            margin: 0;
            padding: 0;
        }
    }
    .FilterHolder{
        width: 30%!important;
    }
}