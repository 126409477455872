@import '../../../../Sass/color.scss';
.ComponentHolder{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgb(238, 238, 238);
    box-sizing: border-box;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 5px rgb(175, 175, 175);
    p{
        span{
            color:$GreenColor ;
        }
    }
}
@media (min-width:999px) {
    .ComponentHolder{
        flex-direction: row!important;
        justify-content: space-between!important;
    }
}