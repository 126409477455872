.FormHolder{
    width: 100%;
    .itemsHolder{
        margin: 0;
        padding: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        li{
            width: 100%;
            margin: 0.5rem;
            max-width: 500px;
            .inputName{
                font-size:0.9rem;
                text-align: center;
                width: 100%;
                margin-bottom: 0.5rem;
            }
            .btnHolder{
                width: 100%;
                margin: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}
@media (min-width:999px) {
    .FormHolder{
        width: 30%!important;
    }
}