.LandingPartHolder{
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .EachPart{
        width: 100%;
        height: 200px;
    }
}
@media (min-width:550px) {
    .EachPart{
        height: 270px!important;
    }
}
@media (min-width:999px) {
    .EachPart{
        width: 50%!important;
    }
}