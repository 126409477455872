@import '../../Sass/color';
.Divider{
    width: 98%;
    height: 2rem;
    margin: 1rem 0;
    border-bottom: 2px solid rgb(221, 221, 221);
    position: relative;
    p{
        color: $GoldColor;
        padding: 0;
        margin: 0;
        position: absolute;
        top:10px;
        right: 50px;
        font-size: 1rem;
        background: #F9F9F9;
        box-sizing: border-box;
        padding: 0.3rem 0.6rem 0.3rem 1rem;
    }
    span{
        background: $GoldColor;
        padding: 0;
        margin: 0;
        width: 3.1rem;
        position: absolute;
        top:27px;
        right: 0;
        box-sizing: border-box;
        padding: 0.3rem 1rem;
        border-radius: 1rem;
    }
}
@media (min-width:999px) {
    .Divider{
        p{
            font-size: 1.3rem!important;
        }
    }
}